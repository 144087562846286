import React from "react"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const CvEntry = ({ title, year, caption }) => (
  <div>
    <Row>
      <Col>
        <div className="d-flex">
          <h6>{title}</h6>
        </div>
      </Col>
      <Col className="text-right">
        <h6>{year}</h6>
      </Col>
    </Row>
    <Row>
      <Col>
        <NewLineText text={caption} />
      </Col>
    </Row>
  </div>
)

export default CvEntry

const NewLineText = ({ text }) => {
  let splitText = text.split("\\n")
  return splitText.map((str, i) => <p key={i}>{str}</p>)
}
