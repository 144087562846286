import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Card, Col, Container, Jumbotron, Row } from "react-bootstrap"

import profilePicture from "../images/about/luk-vermeulen.jpg"
import CvEntry from "../components/cvEntry"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleDoubleDown,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons"

const AboutPage = () => (
  <Layout>
    <SEO title="About Luk Vermeulen" />
    <Jumbotron className=" mt-0" fluid>
      <Container>
        <h1 className="mb-4">Luk Vermeulen</h1>
        <p>
          Growing up in a small village fostered a deep connection to nature and
          craftsmanship. This lead me to picking up blacksmithing as a hobby as
          a way of expressing my early drive to design and to create functional
          objects. Spending lots of time programming and tinkering developed my
          strong passion for technologies.
        </p>
        <p>
          Architecture for me is the pursuit of both my passions: design and
          technology. The ability to create beautiful spaces and the
          unbelievable potential new technologies bring to the table excite me.
        </p>
        <p className="text-center mt-4">
          <a
            className="btn btn-outline-dark btn-lg square"
            href="#cv"
            role="button"
          >
            <FontAwesomeIcon icon={faAngleDoubleDown} />
          </a>
        </p>
      </Container>
    </Jumbotron>
    <Container id="cv">
      <Row>
        <Col sm="6" md="5" lg="4">
          <Card className="mt-0">
            <Card.Img src={profilePicture} />
            <Card.Body>
              <h4 className="card-title">Luk Vermeulen</h4>
              <p className="card-text">
                Always looking for Innovation and Optimization. Working student
                at Formitas AG and student assistant at chair for Design
                Computation. Master student of architecture at RWTH Aachen.
              </p>
              <a
                className="btn btn-outline-dark btn-lg"
                href="http://www.linkedin.com/in/luk-vermeulen"
                role="button"
                title="LinkedIn"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
              <Link to="/contact" className="btn btn-outline-dark btn-lg ml-2">
                <FontAwesomeIcon icon={faEnvelope} />
              </Link>
            </Card.Body>
          </Card>
          <section className="mt-3">
            <div className="d-flex">
              <h4>Skills</h4>
            </div>
            <div>
              <div className="d-flex">
                <h6>Revit</h6>
                <h6 className="ml-auto">highly proficient</h6>
              </div>
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{ width: "95%" }}
                  aria-valuenow="95"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div>
              <div className="d-flex">
                <h6>AutoCAD</h6>
                <h6 className="ml-auto">highly proficient</h6>
              </div>
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{ width: "95%" }}
                  aria-valuenow="95"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div>
              <div className="d-flex">
                <h6>Photoshop</h6>
                <h6 className="ml-auto">Advanced</h6>
              </div>
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{ width: "80%" }}
                  aria-valuenow="80"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div>
              <div className="d-flex">
                <h6>Blender</h6>
                <h6 className="ml-auto">Advanced</h6>
              </div>
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{ width: "80%" }}
                  aria-valuenow="80"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div>
              <div className="d-flex">
                <h6>Adobe Creative Cloud</h6>
                <h6 className="ml-auto">Intermediate</h6>
              </div>
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{ width: "60%" }}
                  aria-valuenow="60"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div>
              <div className="d-flex">
                <h6>Rhino/Grasshopper</h6>
                <h6 className="ml-auto">Intermediate</h6>
              </div>
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{ width: "60%" }}
                  aria-valuenow="60"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div>
              <div className="d-flex">
                <h6>Unreal Engine</h6>
                <h6 className="ml-auto">Intermediate</h6>
              </div>
              <div className="progress" style={{ height: "10px" }}>
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{ width: "60%" }}
                  aria-valuenow="60"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </section>
          <section className="mt-3" id="languages">
            <div className="d-flex">
              <h4>Languages</h4>
            </div>
            <div>
              <div className="d-flex">
                <h6>German</h6>
                <h6 className="ml-auto">native speaker</h6>
              </div>
              <div
                className="progress d-none d-md-flex"
                style={{ height: "10px" }}
              >
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{ width: "100%" }}
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="d-flex">
                <h6>Dutch</h6>
                <h6 className="ml-auto">fluent</h6>
              </div>
              <div
                className="progress d-none d-md-flex"
                style={{ height: "10px" }}
              >
                <div
                  className="progress-bar bg-info"
                  style={{ height: "10px" }}
                  role="progressbar"
                  style={{ width: "90%" }}
                  aria-valuenow="90"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="d-flex">
                <h6>English</h6>
                <h6 className="ml-auto">proficient</h6>
              </div>
              <div
                className="progress d-none d-md-flex"
                style={{ height: "10px" }}
              >
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{ width: "80%" }}
                  aria-valuenow="80"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="d-flex">
                <h6>French</h6>
                <h6 className="ml-auto">conversational</h6>
              </div>
              <div
                className="progress d-none d-md-flex"
                style={{ height: "10px" }}
              >
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{ width: "50%" }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </section>
        </Col>
        <Col>
          <h4 className="pb-3">Profile</h4>
          <Row className="pb-5">
            <Col className="text-justify">
              <p>
                {" "}
                The integration of technology into architecture is a challenge I
                very much enjoy. Having completed projects with BIM and CAD
                software and my teaching experience give me great confidence in
                my software skills. I strongly believe in good teamwork and love
                working in creative environments. I also believe in great
                organization which was very useful to me while working on two
                other jobs besides the educational workload. Beeing my favourite
                project this far, the integrated project aroused my great
                interest in skypscrapers. Therefore my hopes are to secure a
                challenging role in the field of big and technologically
                advanced buildings, in a fast and team oriented environment that
                makes full use of new technologies.{" "}
              </p>
            </Col>
          </Row>

          <h4 className="pb-3">Work experience</h4>
          <CvEntry
            title="Formitas, Cologne"
            year="2020, 4 mos"
            caption="Internship with focus on software- and BIM-Development"
          />
          <CvEntry
            title="KAAN Architecten, Rotterdam"
            year="2019 - 2020, 6 mos"
            caption="Internship at KAAN Architecten"
          />
          <CvEntry
            title="Formitas, Cologne"
            year="2017 - 2019, 2 yrs 1 mo"
            caption="Supporting the VR-Team with project and feature development"
          />
          <CvEntry
            title="Chair for Design Computation - RWTH Aachen University"
            year="2017 - 2019, 2 yrs 6 mos"
            caption="Teaching first and second years' students the basics of CAAD, architecture visualization and BIM"
          />
          <CvEntry
            title="Chair for Sculptural Design - RWTH Aachen University"
            year="2018, 6 mos"
            caption="Supervising stone sculptor courses"
          />

          <h4 className="pb-3">Education</h4>

          <CvEntry
            title="B.Sc. Architecture, RWTH Aachen University"
            year="2016 - 2019"
            caption="Pursuing architecture studies to fulfill the passion of bringing design and technology together"
          />
          <CvEntry
            title="Highschool"
            year="2013 - 2016"
            caption="Developing and programming a 'Smart Home' concept as part of the project course 'Renewable Energies'
						and beeing part of several extracurricular activities."
          />

          <h5 className="pb-3">Other</h5>
          <CvEntry
            title="Programming"
            year=""
            caption="Experience in the field of web development (Frontend and Backend) with HTML, CSS and JavaScript (primarily React and NodeJS).
            \n Basic programming knowledge with Python, Java and C++.
            \n Visual Programming knowledge with Blueprints in Unreal Engine and Grasshopper."
          />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage
